import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetDevicesQuery } from "../features/api/deviceSlice";
import { getStateLocationID } from "../features/authSlice";
import { pickDeviceFromSearch } from "../features/uiSlice";
import { CounterSensor, Device, RaySensor, ThermalSensor } from "../types/typings";
import DeviceCounterSensor from "./DeviceInfoBadge/DeviceCounterSensor";
import DeviceRaySensor from "./DeviceInfoBadge/DeviceRaySensor";
import DeviceThermalSensor from "./DeviceInfoBadge/DeviceThermalSensor";
import DraggableDeviceWrapperV2 from "./Models/DraggableDeviceWrapperV2";

type Props = {
  id: string;
};

const getRandomPosition = () => [0.5 + Math.random() * 0.55, 0.09, 0.2 + Math.random() * 0.155];

export default function DeviceInstance({ id }: Props) {
  const dispatch = useDispatch();
  const locationID = useSelector(getStateLocationID);
  const pickedDevice = useSelector((state: any) => state.ui.pickedDevice);

  const setPicked = (device: Device | null) => {
    dispatch(pickDeviceFromSearch(device));
  };

  const { device } = useGetDevicesQuery(`${locationID}_getDevices`, {
    selectFromResult: ({ data }) => ({
      device: data?.find((d: Device) => d.id === id),
    }),
  });

  const randomPosition = useMemo(getRandomPosition, [id]);
  const position = useMemo(() => {
    return device?.map.pinned ? [device.map.x, 0.001, device.map.y] : randomPosition;
  }, [device, randomPosition]);

  if (!device) {
    return null;
  }

  const deviceComponentMap: Record<string, JSX.Element> = {
    counter: <DeviceCounterSensor device={device as CounterSensor} />,
    thermal: <DeviceThermalSensor device={device as ThermalSensor} />,
    ray: <DeviceRaySensor device={device as RaySensor} />,
  };

  const DeviceComponent = deviceComponentMap[device.__model] || (
    <DeviceCounterSensor device={device as CounterSensor} />
  );

  return (
    <DraggableDeviceWrapperV2
      key={device.id}
      position={position}
      device={device}
      picked={pickedDevice}
      setPicked={setPicked}>
      {DeviceComponent}
    </DraggableDeviceWrapperV2>
  );
}
