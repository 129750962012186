import { useLoader } from "@react-three/fiber";
// import { dispose, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

type Props = {
  mapSrc: string;
};

export default function Classroom({ mapSrc }: Props) {
  // const { picked_location } = useSelector((state: any) => state.auth);
  // const currentMapSrc: string = maps?.[picked_location?.id];
  // console.log("currentMapSrc", currentMapSrc);

  return <Map url={mapSrc} />;
}
const cleanMaterial = (material: any) => {
  material.dispose();
  // dispose textures
  for (const key in material) {
    const value = material[key];
    if (value && typeof value === "object" && "minFilter" in value) {
      value.dispose();
    }
  }
};
const Map = ({ url }: { url: string }) => {
  const gltf = useLoader(GLTFLoader, url);
  useEffect(() => {
    if (!gltf) {
      console.error("GLTF model failed to load");
      return;
    }

    gltf.scene.traverse((child) => {
      //@ts-ignore
      if (child.geometry) child.geometry.dispose();
      //@ts-ignore
      if (child.material) {
        //@ts-ignore
        if (child.material.isMaterial) {
          //@ts-ignore
          cleanMaterial(child.material);
        } else {
          // an array of materials
          //@ts-ignore
          for (const material of child.material) cleanMaterial(material);
        }
      }
    });
    //@ts-ignore
    function cleanMaterial(material) {
      material.dispose();
      // dispose textures
      for (const key in material) {
        const value = material[key];
        if (value && typeof value === "object" && "minFilter" in value) {
          value.dispose();
        }
      }
    }
  }, [gltf]);

  if (!gltf) {
    return null;
  }

  return <primitive object={gltf.scene} scale={[1, 0.05, 1]} position={[0, 0, 0]} />;
};
