import { OrthographicCamera, View, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import { useSelector } from "react-redux";
import DeviceInfoSideBar from "../DeviceInfoSidebar/DeviceInfoSideBar";
import GroupInfoSidebar from "../GroupInfoSidebar/GroupInfoSidebar";
import MainView from "./MainView";
export function AllViews() {
  const ref = useRef();

  const { pickedDevice, pickedArea } = useSelector((state) => state.ui);

  return (
    <Suspense fallback={null}>
      <div ref={ref} className="fixed top-0 left-0 w-full h-full z-[1]">
        <View index={1} className="view1 pointer-events-auto">
          <MainView />
          {/* <OrbitControls makeDefault /> */}
          <OrthographicCamera makeDefault position={[0, 5, 0]} zoom={480} />
        </View>
        {/* <View index={2} className="view2">
          <OrthographicCamera makeDefault position={[0, 5, 0]} zoom={80} />
          <Lights />
          <OrbitControls makeDefault />

          <Bounds fit clip observe margin={1.5}>
            <Target key={1} />
          </Bounds>
        </View> */}

        <Canvas
          style={{ height: "100vh" }}
          onCreated={({ gl }) => {
            gl.setClearColor("black");
            gl.setPixelRatio(Math.min(window.devicePixelRatio, 2));
          }}
          shadows={false}
          eventSource={ref}
          frameloop="demand"
          className="canvas">
          <View.Port />
        </Canvas>
      </div>
      <div
        className={`fixed z-30  top-0 right-0 w-1/3 h-screen flex flex-col transition-all ${
          pickedDevice || pickedArea
            ? "pointer-events-auto translate-x-0 opacity-100"
            : "pointer-events-none translate-x-full opacity-0"
        }`}>
        {pickedDevice && <DeviceInfoSideBar device={pickedDevice} />}
        {pickedArea && <GroupInfoSidebar area={pickedArea} />}
      </div>
    </Suspense>
  );
}

function Lights() {
  return (
    <>
      <ambientLight intensity={1} />
      <pointLight position={[20, 30, 10]} />
      <pointLight position={[-10, -10, -10]} color="blue" />
    </>
  );
}

export function Target(props) {
  const gltf = useGLTF("/models/classroom/map3.glb");
  return <primitive object={gltf.scene.clone()} scale={[1, 0.05, 1]} position={[0, 0, 0]} />;
}
